<template>
  <client-only>
    <template v-if="text">
      <span
        v-if="userIsAuthenticated"
        class="account-button--text"
        :class="$attrs.class"
        @click="navigateToAccount"
      >
        {{ $t('my_account') }}
      </span>
      <span
        v-else
        class="account-button--text"
        :class="$attrs.class"
        @click="navigateToLogin"
      >
        {{ $t('login') }}
      </span>
    </template>
    <template v-else>
      <app-button
        v-if="userIsAuthenticated"
        class="account-button"
        :class="$attrs.class"
        secondary
        light
        @click="navigateToAccount"
      >
        <span class="account-button__text">{{ $t('my_account') }}</span>
        <icon class="account-button__icon" name="line-md:account" />
      </app-button>
      <app-button
        v-else
        class="account-button"
        :class="$attrs.class"
        secondary
        light
        @click="navigateToLogin"
      >
        <span class="account-button__text">{{ $t('login') }}</span>
        <icon class="account-button__icon" name="line-md:account" />
      </app-button>
    </template>

    <template #fallback>
      <app-button
        class="account-button"
        :class="$attrs.class"
        secondary
        light
      >
        <span class="account-button__text">{{ $t('login') }}</span>
        <icon class="account-button__icon" name="line-md:account" />
      </app-button>
    </template>
  </client-only>
</template>

<script setup>
  import { storeToRefs } from 'pinia'
  import { useUserStore } from '~/stores/user'
  import { ROUTES } from '~/lib/constants/routes'

  const localePath = useLocalePath()
  const router = useRouter()
  const userStore = useUserStore()
  const { isAuthenticated: userIsAuthenticated } = storeToRefs(userStore)

  defineProps({
    text: {
      type: Boolean,
      default: false,
    },
  })

  function navigateToAccount() {
    router.push(localePath(ROUTES.ACCOUNT))
  }

  function navigateToLogin() {
    router.push(localePath(ROUTES.LOGIN))
  }
</script>

<style lang="scss">
  .account-button.app-button {
    padding: 12px;

    @include breakpoint(desktop) {
      padding: 18px 32px;
    }
  }

  .account-button--text.app-button {
    padding: 0;
  }

  .account-button--text .account-button__text {
    display: inline;
  }

  .account-button--text .account-button__icon {
    display: none;
  }

  .account-button__text {
    display: none;

    @include breakpoint(desktop) {
      display: inline;
    }
  }

  .account-button__icon {
    @include breakpoint(desktop) {
      display: none!important;
    }
  }
</style>
